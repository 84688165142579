.react-resizable-handle-n {
  width: 100%;
  height: 10px;
  cursor: ns-resize;
  top: 0;
  left: 0;
  transform: rotate(0deg);
  z-index: 100;
  background-color: white;
  background-image: none;
  padding: 0;
  margin: 0;
  transition: background-color 0.2s ease-in-out;
}

.react-resizable-handle-n:hover {
  background-color: #8e8ed4;
}
